











import { ref, computed, watch } from '@vue/composition-api';
import { Operant, Field } from '../constants';

export default {
    name: 'AccessPolicyOperantSelect',
    model: {
        prop: 'selection',
        event: 'update-selection',
    },
    props: {
        selection: Operant,
        field: { type: Field, required: true },
    },
    setup(props: any, { emit }: { emit: any }) {
        const key = ref(props.selection ? props.selection.key : null);
        const currentField = ref(props.field);

        const selectedOperant = computed(() => Operant.find(key.value));
        const updateSelection = () => {
            emit('update-selection', selectedOperant.value);
        };

        const operants = computed(() => props.field.fieldType.operants);

        watch(
            () => props.field,
            (changedField) => {
                if (currentField.value.key !== changedField.key) {
                    let newOperant = operants.value.length > 0 ? operants.value[0] : null;

                    operants.value.forEach((operant: Operant) => {
                        if (props.selection && operant.key === props.selection.key) {
                            newOperant = operant;
                        }
                    });
                    key.value = newOperant ? newOperant.key : null;
                    emit('update-selection', newOperant);
                }
            },
        );

        return { key, operants, selectedOperant, updateSelection };
    },
};
