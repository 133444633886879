import { Policy } from './policy';

export class GeneralPolicy implements Policy {
    static readonly ALLOW_ALL = new GeneralPolicy(true, 'Allow everyone to view this data asset');

    static readonly DENY_ALL = new GeneralPolicy(false, 'Deny everyone to view this data asset');

    static readonly DENY_OTHER_ORGS = new GeneralPolicy(false, 'Deny other organizations to view this data asset');

    readonly allow: boolean;

    readonly text: string;

    constructor(allow: boolean, text: string) {
        this.allow = allow;
        this.text = text;
    }

    static all() {
        return [this.ALLOW_ALL, this.DENY_ALL, this.DENY_OTHER_ORGS];
    }

    public toJSON() {
        return {
            conditions: [],
            actions: ['Retrieve'],
            effect: this.allow,
        };
    }
}
