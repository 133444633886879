









import { ExceptionPolicy } from '../models';

export default {
    name: 'AccessPolicyPreview',
    props: {
        policy: {
            type: ExceptionPolicy,
            required: true,
        },
    },
};
