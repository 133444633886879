


































































import { ref, computed } from '@vue/composition-api';
import { BadgeCheckIcon, MenuIcon, XIcon } from '@vue-hero-icons/outline';

export default {
    name: 'ModelFeature',
    props: {
        feature: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: true,
        },
        selections: {
            type: Array,
            default: () => [],
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    components: { BadgeCheckIcon, MenuIcon, XIcon },
    setup(props: any, { emit }: any) {
        const inHoverMode = ref(false);

        const onCheck = () => {
            if (!props.selections.includes(props.feature)) {
                props.selections.push(props.feature);
                emit('check', props.feature);
            } else {
                props.selections.splice(props.selections.indexOf(props.feature), 1);
                emit('uncheck', props.feature);
            }
        };

        const deleteFeature = () => {
            emit('delete', props.feature);
        };

        const checked = computed(() => props.selections.includes(props.feature));

        return { inHoverMode, onCheck, checked, deleteFeature };
    },
};
