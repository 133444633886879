





























































































import { ref, computed } from '@vue/composition-api';
import { DropdownMenu } from '@/app/components';
import { ExceptionPolicy } from '../models';
import AccessPolicyEdit from './AccessPolicyEdit.vue';
import AccessPolicyPreview from './AccessPolicyPreview.vue';

export default {
    name: 'AccessPolicyNode',
    model: {
        prop: 'policy',
        event: 'save',
    },
    props: {
        policy: {
            type: ExceptionPolicy,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    components: { DropdownMenu, AccessPolicyEdit, AccessPolicyPreview },
    setup(props: any, { emit }: { emit: any }) {
        const inHoverMode = ref(false);
        const showOptionsMenu = ref(false);

        const inEditMode = computed({
            get: () => {
                return props.editMode;
            },
            set: (editMode: boolean) => {
                emit('update-edit-mode', editMode);
            },
        });

        const options = ref([
            {
                name: 'Edit',
                action: () => {
                    emit('edit', props.policy);
                },
            },
            {
                name: 'Delete',
                requiresConfirm: true,
                action: () => {
                    emit('destroy', props.policy);
                },
            },
        ]);

        const savePolicy = (policy: ExceptionPolicy) => {
            inEditMode.value = false;
            emit('save', policy);
        };

        return { inHoverMode, showOptionsMenu, options, inEditMode, savePolicy, emit };
    },
};
